import React, { useState } from 'react'
import TopNavBar from './topNavBar'
import SearchArea from './searchArea'
import './App.css'

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState({ userId: null, username: null })
  const [results, setResults] = useState(null)
  return (
    <div className="App">
      <TopNavBar userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />
      <SearchArea setResults={setResults} />
      <SearchResults results={results} />
    </div>
  )
}

export default App
