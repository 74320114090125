import React from 'react'
// import { Form } from 'react-bootstrap'
import Select from 'react-select'

const DropdownWrapper = (props) => {
    const { id, label, options, handleChange } = props


    return (
        // <Form.Group controlId={id}>
        //     <Form.Label>{label}</Form.Label>
        <Select
            isMulti
            options={options}
            onChange={handleChange(id)}
        />
        // {/* <Form.Control
        //     as="select"
        //     name={id}
        //     className="rounded-0 shadow"
        //     onChange={handleChange}
        // >
        //     {Array.isArray(options) ? options.map(
        //         (opt) => <option key={opt.key ?? opt.value} value={opt.value}>{opt.label ?? opt.value}</option>
        //     ) : options}
        // </Form.Control> */}
        // </Form.Group>
    )
    // <span>
    //     <label for={props.id}>{labelText}</label>
    //     <select name={props.id} id={props.id} class="form-control">
    //         {props.options}
    //     </select>
    // </span>
}

export default DropdownWrapper