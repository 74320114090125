import React from 'react'
import ReactSlider from 'react-slider'
import styled from 'styled-components'

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    max-width: 500px;
    height: 50px;
    border: 1px solid grey;
`

const StyledThumb = styled.div`
    top: 1px;
    width: 50px;
    height: 48px;
    line-height: 38px;
    font-size: 0.9em;
    text-align: center;
    background-color: black;
    color: white;
    cursor: grab;
    border: 5px solid gray;
    box-sizing: border-box;
`

const WideStyledThumb = styled.div`
    top: 1px;
    width: 65px;
    height: 48px;
    line-height: 38px;
    font-size: 0.9em;
    text-align: center;
    background-color: black;
    color: white;
    cursor: grab;
    border: 5px solid gray;
    box-sizing: border-box;
`

const StyledTrack = styled.div`
    position: relative;
    top: 20px;
    height: 10px;
    background: ${props => (props.index === 2 ? '#f00' : props.index === 1 ? '#0f0' : '#ddd')};
`

const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>
const WideThumb = (props, state) => <WideStyledThumb {...props}>{state.valueNow}</WideStyledThumb>

const Track = (props, state) => (<StyledTrack {...props} index={state.index} />)

const Range = (props) => {
    const { id, label, minmax, isWide, handleChange } = props
    const min = minmax[0] ?? 0
    const max = minmax[1] ?? 100
    const step = minmax[2] ?? 1
    const [currValue, setCurrValue] = React.useState([min, max])
    const ariaLabel = [`Minimum ${label}`, `Maxmimum ${label}`]
    const onChange = (value, _index) => {
        console.log("range value:", id, JSON.stringify(value))
        setCurrValue(value)
        handleChange(id, value)
    }

    return (
        <span>
            <label className="form-label">{label}</label>
            <StyledSlider
                id={id}
                min={min}
                max={max}
                step={step}
                minDistance={step}
                defaultValue={[min, max]}
                value={currValue}
                onChange={onChange}
                ariaLabel={ariaLabel}
                renderThumb={isWide ? WideThumb : Thumb}
                renderTrack={Track}
            />
        </span>
    )
}

export default Range