export const MINMAX_MSRP = [13000, 150000, 1000]
export const MINMAX_USED_PRICE = [13000, 150000, 1000]
export const MINMAX_LENGTH = [143, 290, 1]
export const MINMAX_WIDTH = [62, 97, 1]
export const MINMAX_HEIGHT = [42, 118, 1]
export const MINMAX_WEIGHT = [2000, 9100, 100]

export const SELECT_YEAR_OPTS = ["2024", "2023", "2022", "2021", "2020"].map((opt) => ({ label: opt, value: opt }))

const _SELECT_BODY_TYPE_OPTS = [
    "Sedan", "SUV", "Truck", "Coupe", "Convertible", "Hatchback", "Wagon", "Minivan", "Van",
]
// "Truck (Regular Cab)",
// "Truck (Crew Cab)",
// "Truck (Access Cab)",
// "Truck (Extended Cab)",
// "Truck (Double Cab)",
// "Truck (SuperCab)",
// "Truck (SuperCrew)",
// "Truck (CrewMax)",
// "Truck (Quad Cab)",
// "Truck (King Cab)",
// "Truck (Mega Cab)",
export const SELECT_BODY_TYPE_OPTS = [
    ..._SELECT_BODY_TYPE_OPTS.map((opt) => ({ label: opt, value: opt })),
    { label: "Extended Van", value: "Ext Van" },
]

export const SELECT_DOORS_OPTS = [
    "2",
    "3",
    "4",
    "5",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_TOTAL_SEATING_OPTS = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "12",
    "15",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_MAKE_OPTS = [
    "Acura",
    "Alfa Romeo",
    "Aston Martin",
    "Audi",
    "Bentley",
    "BMW",
    "Buick",
    "Cadillac",
    "Chevrolet",
    "Chrysler",
    "Dodge",
    "FIAT",
    "Ford",
    "Genesis",
    "GMC",
    "Honda",
    "Hyundai",
    "INFINITI",
    "Jaguar",
    "Jeep",
    "Karma",
    "Kia",
    "Lamborghini",
    "Land Rover",
    "Lexus",
    "Lincoln",
    "Lotus",
    "Lucid",
    "Maserati",
    "Mazda",
    "McLaren",
    "Mercedes-Benz",
    "MINI",
    "Mitsubishi",
    "Nissan",
    "Polestar",
    "Porsche",
    "Ram",
    "Rivian",
    "Rolls-Royce",
    "Subaru",
    "Tesla",
    "Toyota",
    "VinFast",
    "Volkswagen",
    "Volvo",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_COUNTRY_OPTS = [
    "United States",
    "South Korea",
    "Germany",
    "Japan",
    "United Kingdom",
    "Sweden",
    "Italy",
    "Vietnam",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_CYLINDERS_OPTS = [
    "I2",
    "I4",
    "flat 4",
    "I3",
    "I5",
    "I6",
    "V6",
    "flat 6",
    "V8",
    "V10",
    "V12",
    "W12",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_VALVES_OPTS = [
    "8",
    "12",
    "16",
    "18",
    "20",
    "24",
    "32",
    "36",
    "40",
    "48",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_CAM_TYPE_OPTS = [
    "Overhead valves (OHV)",
    "Single overhead cam (SOHC)",
    "Double overhead cam (DOHC)",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_DRIVE_TYPE_OPTS = [
    "rear wheel drive",
    "front wheel drive",
    "all wheel drive",
    "four wheel drive",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_TRANSMISSION_OPTS = [
    "Any automatic",
    "Any manual",
    "1-speed direct drive",
    "2-speed automated manual",
    "4-speed shiftable automatic",
    "5-speed manual",
    "5-speed shiftable automatic",
    "5-speed automatic",
    "6-speed manual",
    "6-speed automated manual",
    "6-speed shiftable automatic",
    "6-speed automatic",
    "7-speed manual",
    "7-speed automated manual",
    "7-speed shiftable automatic",
    "7-speed automatic",
    "8-speed automated manual",
    "8-speed shiftable automatic",
    "8-speed automatic",
    "9-speed automated manual",
    "9-speed shiftable automatic",
    "9-speed automatic",
    "10-speed shiftable automatic",
    "10-speed automatic",
    "continuously variable-speed automatic",
    "electrically variable-speed automatic",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_ENGINE_TYPE_OPTS = [
    "gas",
    "diesel",
    "flex-fuel (FFV)",
    "hybrid",
    "mild hybrid",
    "plug-in hybrid",
    "electric",
    "electric (fuel cell)",
].map((opt) => ({ label: opt, value: opt }))

export const SELECT_FUEL_TYPE_OPTS = [
    "regular unleaded",
    "premium unleaded (recommended)",
    "premium unleaded (required)",
    "flex-fuel (unleaded/E85)",
    "diesel fuel",
    "electric",
    "hydrogen",
].map((opt) => ({ label: opt, value: opt }))
