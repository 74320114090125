import React from 'react'
import { useMergeState } from './utils'
// import {
//     FormControl,
//     Form,
//     Card,
// } from "react-bootstrap"
import { JSON_FETCH_HEADERS } from './constants'
import DropdownWrapper from './search_fields/dropdownWrapper'
import Range from './search_fields/range'
import * as c from './search_fields/constants'

const SearchArea = (props) => {
    const { setResults } = props
    const [state, setState] = useMergeState({
        year: [],
        body_type: [],
        doors: [],
        total_seating: [],
        make: [],
        country_of_origin: [],
        cylinders: [],
        valves: [],
        cam_type: [],
        drive_type: [],
        transmission: [],
        engine_type: [],
        fuel_type: [],
        range_base_msrp: [c.MINMAX_MSRP[0], c.MINMAX_MSRP[1]],
        range_used_price: [c.MINMAX_USED_PRICE[0], c.MINMAX_USED_PRICE[1]],
        range_length: [c.MINMAX_LENGTH[0], c.MINMAX_LENGTH[1]],
        range_width: [c.MINMAX_WIDTH[0], c.MINMAX_WIDTH[1]],
        range_height: [c.MINMAX_HEIGHT[0], c.MINMAX_HEIGHT[1]],
        range_weight: [c.MINMAX_WEIGHT[0], c.MINMAX_WEIGHT[1]],
    })

    const cleanRange = (minmax, vals) => {
        const minrange = vals[0] == minmax[0] ? "*" : vals[0]
        const maxrange = vals[1] == minmax[1] ? "*" : vals[1]
        if (minrange === "*" && maxrange === "*") {
            return []
        }
        return [minrange, maxrange]
    }

    const buildFilters = () => {
        const filters = {}
        for (const [key, value] of Object.entries(state)) {
            let cleanVal = []
            if (key === "range_base_msrp") {
                cleanVal = cleanRange(c.MINMAX_MSRP, value)
            } else if (key === "range_used_price") {
                cleanVal = cleanRange(c.MINMAX_USED_PRICE, value)
            } else if (key === "range_length") {
                cleanVal = cleanRange(c.MINMAX_LENGTH, value)
            } else if (key === "range_length") {
                cleanVal = cleanRange(c.MINMAX_LENGTH, value)
            } else if (key === "range_width") {
                cleanVal = cleanRange(c.MINMAX_WIDTH, value)
            } else if (key === "range_height") {
                cleanVal = cleanRange(c.MINMAX_HEIGHT, value)
            } else if (key === "range_weight") {
                cleanVal = cleanRange(c.MINMAX_WEIGHT, value)
            } else if (value.length > 0) {
                // always an array of options
                cleanVal = value.map((val) => val.value)
            }
            if (cleanVal.length > 0) {
                filters[key] = cleanVal
            }
        }
        return JSON.stringify(filters)
    }

    const searchCars = (e) => {
        e.preventDefault()
        const searchFilters = buildFilters()
        // console.log("searchFilters: " + searchFilters)
        fetch(`/search`, {
            method: "post",
            headers: JSON_FETCH_HEADERS,
            body: searchFilters,
        })
            .then(response => response.json())
            .then(data => {
                console.log("search results:" + JSON.stringify(data))
                setResults(data)
            })
            .catch(error => alert("Search Error: " + error))

        // const changeRange = (e) => {
        //     e.preventDefault()
        //     const data = e.detail
        //     const form = document.forms['search-form']
        //     // console.log(data.sliderId, data.minRangeValue, data.maxRangeValue, form.elements[data.sliderId + '_min'].name, form.elements[data.sliderId + '_min'].value, JSON.stringify(form.elements))
        //     // console.log(data.sliderId, data.minRangeValue, data.maxRangeValue)
        //     form.elements[data.sliderId + '_min'].value = data.minRangeValue
        //     form.elements[data.sliderId + '_max'].value = data.maxRangeValue
        //     // console.log("set:", form.elements[data.sliderId + '_min'].value, form.elements[data.sliderId + '_max'].value)
        // }
    }

    const handleChange = (id) => (selectedOption) => {
        setState({ [id]: selectedOption })
    }

    const rangeChange = (id, rangeVal) => {
        setState({ [id]: rangeVal })
    }

    return (
        <div>
            <form id="search-form" name="search-form" action="/search" method="POST">
                <div id="form-area-container">
                    {/* YEAR / PRICE */}
                    <div id="core-fields" class="form-area">
                        <h4>Year / Price</h4>
                        <DropdownWrapper id="year" label="Year" options={c.SELECT_YEAR_OPTS} handleChange={handleChange} />
                        <Range id="range_base_msrp" label="MSRP ($)" minmax={c.MINMAX_MSRP} isWide handleChange={rangeChange} />
                        <Range id="range_used_price" label="Used Price ($)" minmax={c.MINMAX_USED_PRICE} isWide handleChange={rangeChange} />
                    </div>
                    {/* BODY / VEHICLE TYPE */}
                    <div id="body-fields" class="form-area">
                        <h4>Body / Vehicle Type</h4>
                        <DropdownWrapper id="body_type" label="Body Type" options={c.SELECT_BODY_TYPE_OPTS} handleChange={handleChange} />
                        <DropdownWrapper id="doors" label="Doors" options={c.SELECT_DOORS_OPTS} handleChange={handleChange} />
                        <DropdownWrapper id="total_seating" label="Total Seating" options={c.SELECT_TOTAL_SEATING_OPTS} handleChange={handleChange} />

                        {/* MAKE */}
                        <div id="make-fields" class="form-area">
                            <h4>Make</h4>
                            <DropdownWrapper id="make" label="Make" options={c.SELECT_MAKE_OPTS} handleChange={handleChange} />
                            <DropdownWrapper id="country_of_origin" label="Country of Origin" options={c.SELECT_COUNTRY_OPTS} handleChange={handleChange} />
                        </div>


                        {/* ENGINE - PHYSICAL */}
                        <div id="engine-fields" class="form-area">
                            <h4>Engine</h4>
                            <DropdownWrapper id="cylinders" label="Cylinders" options={c.SELECT_CYLINDERS_OPTS} handleChange={handleChange} />
                            <DropdownWrapper id="valves" label="Valves" options={c.SELECT_VALVES_OPTS} handleChange={handleChange} />
                            <DropdownWrapper id="cam_type" label="Cam Type" options={c.SELECT_CAM_TYPE_OPTS} handleChange={handleChange} />
                            <DropdownWrapper id="drive_type" label="Drive Type" options={c.SELECT_DRIVE_TYPE_OPTS} handleChange={handleChange} />
                            <DropdownWrapper id="transmission" label="Transmission" options={c.SELECT_TRANSMISSION_OPTS} handleChange={handleChange} />
                        </div>

                        {/* FUEL */}
                        <div id="fuel-fields" class="form-area">
                            <h4>Fuel</h4>
                            <DropdownWrapper id="engine_type" label="Engine Type" options={c.SELECT_ENGINE_TYPE_OPTS} handleChange={handleChange} />
                            <DropdownWrapper id="fuel_type" label="Fuel Type" options={c.SELECT_FUEL_TYPE_OPTS} handleChange={handleChange} />
                        </div>

                        {/* PHYSICAL DIMENSIONS */}
                        <div id="dimensions-fields" class="form-area">
                            <h4>Dimensions</h4>
                            <Range id="range_length" label="Length (inches)" minmax={c.MINMAX_LENGTH} handleChange={rangeChange} />
                            <Range id="range_width" label="Width (inches)" minmax={c.MINMAX_WIDTH} handleChange={rangeChange} />
                            <Range id="range_height" label="Height (inches)" minmax={c.MINMAX_HEIGHT} handleChange={rangeChange} />
                            <Range id="range_weight" label="Curb Weight (lbs)" minmax={c.MINMAX_WEIGHT} handleChange={rangeChange} />
                        </div>
                    </div>

                    <input id="searchCarsBtn" type="submit" value="Find Your Car" onClick={searchCars} />
                </div>
            </form>
        </div>
    )
}

export default SearchArea
